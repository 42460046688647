import { UsergroupDeleteOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";

const Boxes2 = () => {
  const deshboard = useSelector((state) =>
    state?.allapi?.deshboard ? state?.allapi?.deshboard : {}
  );
  console.log(deshboard);
  // const totalUsers = useSelector(state => state.dashboard.totalUsers || "");
  // const totalFaqs = useSelector(state => state.dashboard.totalFaqs || "");
  // const TotalJobsAdd = useSelector(state => state.dashboard.TotalJobs || "");
  // const totalJobApp = useSelector(state => state.jobAppliction.totalJobApp || 0);
  // console.log(totalJobApp);
  return (
    <Row gutter={[0, 20]}>
      {/* <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="row p-10  bg-1 m-5 cursor-pointer align-item-center"
          //   onClick={() => navigate("/users")}
        >
          <div className="col-6 flex justify-content-between align-item-center">
            <div className=" d-flex align-items-center fw-600">
              <div style={{}} className="icbg1">
                <UsergroupDeleteOutlined />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                // alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div className="relative boxHeadingTotal fw-600">User</div>
              <div className="priceText">{deshboard?.ActiveUser}</div>
            </div>
          </div>
          <div className="col-6 d-flex justify-content-end align-items-center">
            <div
              className="relative boxHeadingTotal fw-600 d-flex align-items-center"
              style={{ width: "30px" }}
            >
              <svg
                className="icon icon-size-16 me-1"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </div>
          </div>
        </div>
      </Col> */}
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="row p-10  bg-2 m-5 cursor-pointer align-item-center"
          //   onClick={() => navigate("/users")}
        >
          <div className="col-6 flex justify-content-between align-item-center">
            <div className=" d-flex align-items-center fw-600">
              <div style={{}} className="icbg2">
                <UsergroupDeleteOutlined />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                // alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div className="relative boxHeadingTotal fw-600">Staff</div>
              <div className="priceText">{deshboard?.ActiveVendor}</div>
            </div>
          </div>
          <div className="col-6 d-flex justify-content-end align-items-center">
            <div
              className="relative boxHeadingTotal fw-600 d-flex align-items-center"
              style={{ width: "30px" }}
            >
              <svg
                className="icon icon-size-16 me-1"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </div>
          </div>
        </div>
      </Col>
      {/* <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="row p-10  bg-3 m-5 cursor-pointer align-item-center"
          //   onClick={() => navigate("/users")}
        >
          <div className="col-9 flex justify-content-between align-item-center">
            <div className=" d-flex align-items-center fw-600">
              <div style={{}} className="icbg3">
                <UsergroupDeleteOutlined />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                // alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div className="relative boxHeadingTotal fw-600">
                {" "}
                Total Order Amount
              </div>
              <div className="priceText">{deshboard?.ActiveVendor}</div>
            </div>
          </div>
          <div className="col-3 d-flex justify-content-end align-items-center">
            <div
              className="relative boxHeadingTotal fw-600 d-flex align-items-center"
              style={{ width: "30px" }}
            >
              <svg
                className="icon icon-size-16 me-1"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="row p-10  bg-4 m-5 cursor-pointer align-item-center"
          //   onClick={() => navigate("/users")}
        >
          <div className="col-9 flex justify-content-between align-item-center">
            <div className=" d-flex align-items-center fw-600">
              <div style={{}} className="icbg4">
                <UsergroupDeleteOutlined />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                // alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div className="relative boxHeadingTotal fw-600">
                {" "}
                Total Sale Amount
              </div>
              <div className="priceText">{deshboard?.ActiveVendor}</div>
            </div>
          </div>
          <div className="col-3 d-flex justify-content-end align-items-center">
            <div
              className="relative boxHeadingTotal fw-600 d-flex align-items-center"
              style={{ width: "30px" }}
            >
              <svg
                className="icon icon-size-16 me-1"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="row p-10  bg-1 m-5 cursor-pointer align-item-center"
          //   onClick={() => navigate("/users")}
        >
          <div className="col-10 flex justify-content-between align-item-center">
            <div className=" d-flex align-items-center fw-600">
              <div style={{}} className="icbg1">
                <UsergroupDeleteOutlined />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                // alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div className="relative boxHeadingTotal fw-600">
                {" "}
                Today Company Busi.
              </div>
              <div className="priceText">{deshboard?.companyBusiness}</div>
            </div>
          </div>
          <div className="col-2 d-flex justify-content-end align-items-center">
            <div
              className="relative boxHeadingTotal fw-600 d-flex align-items-center"
              style={{ width: "30px" }}
            >
              <svg
                className="icon icon-size-16 me-1"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </div>
          </div>
        </div>
      </Col>

      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="row p-10  bg-2 m-5 cursor-pointer align-item-center"
          //   onClick={() => navigate("/users")}
        >
          <div className="col-10 flex justify-content-between align-item-center">
            <div className=" d-flex align-items-center fw-600">
              <div style={{}} className="icbg2">
                <UsergroupDeleteOutlined />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                // alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div className="relative boxHeadingTotal fw-600">
                {" "}
                Today Comp. Busi. GST
              </div>
              <div className="priceText">
                {deshboard?.companyBusinessWithGst?.toFixed(2)}
              </div>
            </div>
          </div>
          <div className="col-2 d-flex justify-content-end align-items-center">
            <div
              className="relative boxHeadingTotal fw-600 d-flex align-items-center"
              style={{ width: "30px" }}
            >
              <svg
                className="icon icon-size-16 me-1"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </div>
          </div>
        </div>
      </Col> */}

      {/* <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="row p-10  bg-3 m-5 cursor-pointer align-item-center"
          //   onClick={() => navigate("/users")}
        >
          <div className="col-8 flex justify-content-between align-item-center">
            <div className=" d-flex align-items-center fw-600">
              <div style={{}} className="icbg3">
                <UsergroupDeleteOutlined />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                // alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div className="relative boxHeadingTotal fw-600">Total Stock</div>
              <div className="priceText">{deshboard?.finalStock}</div>
            </div>
          </div>
          <div className="col-4 d-flex justify-content-end align-items-center">
            <div
              className="relative boxHeadingTotal fw-600 d-flex align-items-center"
              style={{ width: "30px" }}
            >
              <svg
                className="icon icon-size-16 me-1"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="row p-10  bg-4 m-5 cursor-pointer align-item-center"
          //   onClick={() => navigate("/users")}
        >
          <div className="col-8 flex justify-content-between align-item-center">
            <div className=" d-flex align-items-center fw-600">
              <div style={{}} className="icbg4">
                <UsergroupDeleteOutlined />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                // alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div className="relative boxHeadingTotal fw-600">
                {" "}
                Total Product
              </div>
              <div className="priceText">{deshboard?.totalProduct}</div>
            </div>
          </div>
          <div className="col-4 d-flex justify-content-end align-items-center">
            <div
              className="relative boxHeadingTotal fw-600 d-flex align-items-center"
              style={{ width: "30px" }}
            >
              <svg
                className="icon icon-size-16 me-1"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </div>
          </div>
        </div>
      </Col> */}
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="row p-10  bg-1 m-5 cursor-pointer align-item-center"
          //   onClick={() => navigate("/users")}
        >
          <div className="col-7 flex justify-content-between align-item-center">
            <div className=" d-flex align-items-center fw-600">
              <div style={{}} className="icbg1">
                <UsergroupDeleteOutlined />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                // alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div className="relative boxHeadingTotal fw-600">
                {" "}
                Total Ticket
              </div>
              <div className="priceText">{deshboard?.totalTicket}</div>
            </div>
          </div>
          <div className="col-5 d-flex justify-content-end align-items-center">
            <div
              className="relative boxHeadingTotal fw-600 d-flex align-items-center"
              style={{ width: "30px" }}
            >
              <svg
                className="icon icon-size-16 me-1"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </div>
          </div>
        </div>
      </Col>

      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="row p-10  bg-2 m-5 cursor-pointer align-item-center"
          //   onClick={() => navigate("/users")}
        >
          <div className="col-10 flex justify-content-between align-item-center">
            <div className=" d-flex align-items-center fw-600">
              <div style={{}} className="icbg2">
                <UsergroupDeleteOutlined />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                // alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div className="relative boxHeadingTotal fw-600">
                {" "}
                Total Pending Expense
              </div>
              <div className="priceText">
                {deshboard?.totalpendingexpense?.toFixed(2)}
              </div>
            </div>
          </div>
          <div className="col-2 d-flex justify-content-end align-items-center">
            <div
              className="relative boxHeadingTotal fw-600 d-flex align-items-center"
              style={{ width: "30px" }}
            >
              <svg
                className="icon icon-size-16 me-1"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </div>
          </div>
        </div>
      </Col>

      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="row p-10  bg-3 m-5 cursor-pointer align-item-center"
          //   onClick={() => navigate("/users")}
        >
          <div className="col-9 flex justify-content-between align-item-center">
            <div className=" d-flex align-items-center fw-600">
              <div style={{}} className="icbg3">
                <UsergroupDeleteOutlined />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                // alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div className="relative boxHeadingTotal fw-600">
                {" "}
                Total Appr. Expense
              </div>
              <div className="priceText">
                {" "}
                {deshboard?.totalapporvedgexpense}
              </div>
            </div>
          </div>
          <div className="col-3 d-flex justify-content-end align-items-center">
            <div
              className="relative boxHeadingTotal fw-600 d-flex align-items-center"
              style={{ width: "30px" }}
            >
              <svg
                className="icon icon-size-16 me-1"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </div>
          </div>
        </div>
      </Col>
      {/* <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="row p-10  bg-4 m-5 cursor-pointer align-item-center"
          //   onClick={() => navigate("/users")}
        >
          <div className="col-8 flex justify-content-between align-item-center">
            <div className=" d-flex align-items-center fw-600">
              <div style={{}} className="icbg4">
                <UsergroupDeleteOutlined />
              </div>
            </div>
            <div  style={{
                display: "flex",
                // alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}>
              <div className="relative boxHeadingTotal fw-600">
                {" "}
                Total Product
              </div>
              <div className="priceText">{deshboard?.totalProduct}</div>
            </div>
          </div>
          <div className="col-4 d-flex justify-content-end align-items-center">
            <div
              className="relative boxHeadingTotal fw-600 d-flex align-items-center"
              style={{ width: "30px" }}
            >
              <svg
                className="icon icon-size-16 me-1"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </div>
          </div>
        </div>
      </Col> */}
      {/* <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad bg-1 m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">User</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText">{deshboard?.ActiveUser}</div>
            <UsergroupDeleteOutlined />
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad bg-2 m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">Staff</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText color-risk">
              {deshboard?.ActiveVendor}
            </div>
          </div>
        </div>
      </Col> */}
      {/* <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad bg-3 m-5 cursor-pointer"
          // onClick={() => navigate("/users?page=1&status=0")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              Total Order Amount
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText">{deshboard?.ActiveVendor}</div>
          </div>
        </div>
      </Col> */}
      {/* <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad bg-4 m-5 cursor-pointer"
          // onClick={() => navigate("/users?page=1&status=1")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">
              Total Sale Amount
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText color-risk">
              {deshboard?.InactiveVendor}
            </div>
          </div>
        </div>
      </Col> */}

      {/* <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad bg-5 m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              Today Company Busi.
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText">{deshboard?.companyBusiness}</div>
          </div>
        </div>
      </Col> */}
      {/* <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad bg-6 m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">
              Today Comp. Busi. With GST
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText color-risk">
              {deshboard?.companyBusinessWithGst?.toFixed(2)}
            </div>
          </div>
        </div>
      </Col> */}

      {/* <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad bg-7 m-5 cursor-pointer"
          //   onClick={() => navigate("/category")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              Total Stock Amount
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText">
              {deshboard?.totalStockProductAmount}
            </div>
          </div>
        </div>
      </Col> */}
      {/* <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad bg-8 m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">Total Stock</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText color-risk">{deshboard?.finalStock}</div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad bg-1 m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">Total Product</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText">{deshboard?.totalProduct}</div>
          </div>
        </div>
      </Col> */}
    </Row>
  );
};

export default Boxes2;
